<template>
	<div class="mtipoimplicadoFormHeader puiformheader">
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mtipoimplicado.idtipoimplicado')" :value="getIdtipoimplicadoValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mtipoimplicado.codtipoimplicado')" :value="getCodtipoimplicadoValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mtipoimplicado.name')" :value="getNameValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mtipoimplicado-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mtipoimplicado'
		};
	},
	computed: {
		getIdtipoimplicadoValue() {
			return this.model && this.model.idtipoimplicado ? this.model.idtipoimplicado : '-';
		},
		getCodtipoimplicadoValue() {
			return this.model && this.model.codtipoimplicado ? this.model.codtipoimplicado : '-';
		},
		getNameValue() {
			return this.model && this.model.name ? this.model.name : '-';
		},
	}
}
</script>
